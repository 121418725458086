
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import CountriesDataTable from "@/components/system/Tables/CountriesDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import CountryModal from "@/components/system/Modals/CountryModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";

export default defineComponent({
  name: "CountriesOverview",
  components: {
    CountriesDataTable,
    LoadingSpinner,
    Pagination,
    CountryModal,
    DeleteModal
  },
  data() {
    return {
      pagination: {},
      isThereAModalActive: false,
      currentlySelectedCountry: {
        id: null,
        title: null,
        shortcode: null,
        is_operational: 0
      },
      showOnlyActive: true,
      modalSetup: {
        action: "",
        title: ""
      }
    };
  },
  props: {
    widgetClasses: String
  },
  methods: {
    ...mapActions("CountriesModule", [
      "fetchData",
      "setCurrentPage",
      "resetState",
      "setSortParams",
      "setShowActive",
      "update",
      "createNew",
      "destroyData"
    ]),
    changeCurrentPage(pageNumber) {
      this.setCurrentPage(pageNumber);
      this.fetchData();
    },
    updateTableSort(sortParams) {
      this.setSortParams(sortParams);
      this.fetchData();
    },
    openEditModal(country) {
      this.modalSetup.title = "Edit Country";
      this.modalSetup.action = "edit";
      this.currentlySelectedCountry = country;
    },
    openAddNewModal() {
      this.resetCountryData();
      this.modalSetup.title = "";
      this.$nextTick(() => (this.modalSetup.title = "Add new Country"));
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    openDeleteModal(entry) {
      this.currentlySelectedCountry = entry;
      this.isThereAModalActive = true;
    },
    handleShowActive() {
      this.setShowActive(this.showOnlyActive);
      this.fetchData();
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          values: dataFromModal.payload,
          id: this.currentlySelectedCountry.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    deleteSelectedCountry() {
      this.destroyData(this.currentlySelectedCountry.id);
      this.isThereAModalActive = false;
      this.currentlySelectedCountry.id = null;
    },
    cancelModal() {
      this.isThereAModalActive = false;
    },
    resetCountryData() {
      this.currentlySelectedCountry = {
        id: null,
        title: null,
        shortcode: null,
        is_operational: 0
      };
    }
  },
  computed: {
    ...mapGetters("CountriesModule", [
      "getPaginationData",
      "getData",
      "loading"
    ])
  },
  mounted() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
