<template>
  <!--begin::Modal-->
  <div
    class="modal fade"
    ref="CountryModal"
    id="CountryModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <Form
          class="form"
          ref="CountryModalForm"
          id="CountryModal_form"
          :validation-schema="validationSchema"
          @submit="submit"
        >
          <!--begin::Modal header-->
          <div class="modal-header" id="CountryModal_header">
            <!--begin::Modal title-->
            <h2>{{ title }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div
              class="scroll-y me-n7 pe-7"
              id="CountryModal_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#CountryModal_header"
              data-kt-scroll-wrappers="#CountryModal_scroll"
              data-kt-scroll-offset="300px"
            >
              <div class="row">
                <div class="col-md-12 fv-row">
                  <!--begin::Label-->
                  <label for="title" class="required fs-5 fw-bold mb-2"
                    >Title</label
                  >
                  <!--end::Label-->

                  <!--begin::Input-->
                  <Field
                    type="text"
                    id="title"
                    class="form-control form-control-solid"
                    placeholder="Enter title"
                    name="title"
                    v-model.trim="countryData.title"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="title" />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
              <div class="row my-5">
                <div class="col-md-12 fv-row">
                  <!--begin::Label-->
                  <label for="shortcode" class="fs-5 fw-bold mb-2"
                    >Shortcode</label
                  >
                  <!--end::Label-->

                  <!--begin::Input-->
                  <Field
                    type="text"
                    id="shortcode"
                    class="form-control form-control-solid"
                    placeholder="Enter Shortcode"
                    name="shortcode"
                    v-model.trim="countryData.shortcode"
                  />
                  <!--end::Input-->
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 fv-row">
                  <div class="form-check form-check-sm me-4">
                    <input
                      class="form-check-input form-check-sm cursor-pointer"
                      type="checkbox"
                      id="is_operational"
                      :checked="countryData.is_operational"
                      @change="handleCheckboxChange"
                    />
                    <label
                      class="form-check-label text-nowrap"
                      for="is_operational"
                      >Is operational</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              type="reset"
              id="CountryModal_cancel"
              class="btn btn-white me-3"
              data-bs-dismiss="modal"
            >
              Discard
            </button>
            <!--end::Button-->

            <!--begin::Button-->
            <button
              :disabled="countryData.title == ''"
              type="submit"
              id="CountryModal_submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Submit </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
    </div>
  </div>

  <!--end::Modal - New Address-->
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';

function initialCountryData() {
  return {
    title: "",
    shortcode: "",
    is_operational: 0
  }};

export default defineComponent({
  name: "CountryModal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: {
    currentlySelectedCountry: Object,
    action : String,
    title : String
  },
  mounted() {
    this.modal = new Modal(this.$refs.CountryModal)
  },
  data() {
    return {
      validationSchema: Yup.object().shape({
        title: Yup.string()
            .required()
            .label("Title")
      }),
      countryData: initialCountryData(),
      modal: null
    };
  },
  methods: {
    handleCheckboxChange(event) {
      if(event.target.checked) {
        this.countryData.is_operational = 1;
      } else {
        this.countryData.is_operational = 0;
      }
    },
    submit() {
      this.validationSchema.isValid(this.countryData).then((validationResult) => {
        if(validationResult){
          this.modal.hide();
          this.$emit("saveChanges", {payload : this.countryData, action : this.action});
        }
      })
    },
  },
  watch: {
    currentlySelectedCountry() {
      if(this.action == "edit" ){
        this.countryData.title = this.currentlySelectedCountry.title
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.countryData.shortcode = this.currentlySelectedCountry.shortcode;
        this.countryData.is_operational = this.currentlySelectedCountry.is_operational;
      }
    },
    title() {
      if(this.action !== "edit") this.countryData = initialCountryData();
      this.$refs.CountryModalForm.resetForm(null);
    }
  }
});
</script>
